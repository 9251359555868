import React from "react";
import { Page, Card } from "@7labs-io/b2b-packages";

export default function Broken() {
  return (
    <Page>
      <div className="h-screen flex justify-center items-center">
        <div className="w-1/2 flex flex-col">
          <Card variant="outlined" className="w-full rounded-xl">
            <div className="px-8">
              <div>
                This is embarassing. We are currently experiencing technical
                difficulties. Please try again later.
              </div>
            </div>
          </Card>
        </div>
      </div>
    </Page>
  );
}
