import React from "react";
import {
  SignedIn,
  SignedOut,
  SignInButton,
  UserButton,
} from "@clerk/clerk-react";

export function ProfileDropdown() {
  return (
    <div className="relative inline-block flex flex-col gap-4 items-end !text-gray-100">
      <SignedIn>
        {/* Mount the UserButton component */}
        <UserButton afterSignOutUrl={import.meta.env.VITE_CLERK_SIGN_OUT_URL} />
      </SignedIn>
      <SignedOut>
        {/* Signed out users get sign in button */}
        <SignInButton />
      </SignedOut>
    </div>
  );
}
