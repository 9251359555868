import React, { useEffect, useState } from "react";
import { Logo } from "@7labs-io/7labs-global-components";
import { ProfileDropdown } from "../common/ProfileDropdown";
import { Link, useLocation, useNavigate } from "react-router-dom";

import { Menu, MenuButton, MenuItem, MenuItems } from "@headlessui/react";
import UilArrowDown from "@iconscout/react-unicons/icons/uil-angle-down";
import UilBell from "@iconscout/react-unicons/icons/uil-bell";
import UilCheck from "@iconscout/react-unicons/icons/uil-check";
import UilChange from "@iconscout/react-unicons/icons/uil-refresh";
import UilStar from "@iconscout/react-unicons/icons/uil-star";
import UilUserPlus from "@iconscout/react-unicons/icons/uil-user-plus";
import { twMerge } from "tailwind-merge";
import { getNotifications } from "../../Services";
import { useQuery } from "@tanstack/react-query";
import { Loading } from "../common/Loading";
import { useUser } from "@clerk/clerk-react";

export default function CompanyManagerHeader() {
  const { isSignedIn, user } = useUser();
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const [memberships, setMemberships] = useState([]);

  const { data: notifications, isLoading } = useQuery({
    queryKey: ["get-notifications"],
    queryFn: getNotifications,
    enabled: !["/admin-7labs"].includes(pathname),
  });

  useEffect(() => {
    (async function run() {
      const m = (await user?.getOrganizationMemberships()) || [];
      setMemberships(m);
    })();
  }, [user?.primaryEmailAddress.emailAddress]);

  const [selected, setSelected] = useState();
  useEffect(() => {
    if (window.location.pathname.indexOf("dashboard") > -1) {
      setSelected("dashboard");
    } else if (window.location.pathname.indexOf("review") > -1) {
      setSelected("review");
    } else {
      setSelected("profile");
    }
  }, [window.location.pathname]);

  let selectedMenuText = "";
  switch (selected) {
    case "profile":
      selectedMenuText = "Profile";
      break;
    case "dashboard":
      selectedMenuText = "Dashboard";
      break;
    case "review":
      selectedMenuText = "Leave a review";
      break;
    default:
      selectedMenuText = "Profile";
      break;
  }

  return (
    <header className="bg-black">
      <nav
        className="mx-auto flex items-center justify-between px-4 md:px-6 lg:px-10 my-2"
        aria-label="Global"
      >
        <Link to="/">
          <span className="sr-only">7labs</span>
          <Logo size="sm" />
        </Link>
        <div className="flex gap-8">
          {memberships[0]?.role !== "org:god" && (
            <Menu>
              <MenuButton className="inline-flex items-center gap-2 py-1.5 px-3 font-semibold text-base !outline-none">
                {selectedMenuText}
                <UilArrowDown className="size-5 fill-base" />
              </MenuButton>

              <MenuItems
                transition
                anchor="bottom end"
                className="flex flex-col gap-1 w-52 origin-top-right rounded-xl border border-base-400 bg-base p-1 text-base-content transition duration-100 ease-out [--anchor-gap:var(--spacing-1)] focus:outline-none data-[closed]:scale-95 data-[closed]:opacity-0 z-10"
              >
                <MenuItem>
                  <button
                    onClick={() =>
                      selected !== "profile" && navigate("/profile")
                    }
                    className={twMerge(
                      "flex w-full items-center justify-between hover:bg-primary-lite hover:text-primary-lite-content transition-all rounded-lg py-1.5 px-3",
                      selected === "profile" &&
                        "bg-primary-lite text-primary-lite-content cursor-default"
                    )}
                  >
                    <span>Profile</span>
                    {selected === "profile" && <UilCheck className="size-4" />}
                  </button>
                </MenuItem>
                <MenuItem>
                  <button
                    onClick={() =>
                      selected !== "dashboard" && navigate("/dashboard")
                    }
                    className={twMerge(
                      "flex w-full items-center justify-between hover:bg-primary-lite hover:text-primary-lite-content transition-all rounded-lg py-1.5 px-3",
                      selected === "dashboard" &&
                        "bg-primary-lite text-primary-lite-content cursor-default"
                    )}
                  >
                    <span>Dashboard</span>
                    {selected === "dashboard" && (
                      <UilCheck className="size-4" />
                    )}
                  </button>
                </MenuItem>
                <MenuItem>
                  <button
                    onClick={() => selected !== "review" && navigate("/review")}
                    className={twMerge(
                      "flex w-full items-center justify-between hover:bg-primary-lite hover:text-primary-lite-content transition-all rounded-lg py-1.5 px-3",
                      selected === "dashboard" &&
                        "bg-primary-lite text-primary-lite-content cursor-default"
                    )}
                  >
                    <span>Leave a review</span>
                    {selected === "review" && <UilCheck className="size-4" />}
                  </button>
                </MenuItem>
              </MenuItems>
            </Menu>
          )}

          {memberships[0]?.role !== "org:god" && (
            <Menu>
              <MenuButton className="inline-flex items-center gap-2 py-1.5 px-3 font-semibold text-base !outline-none">
                <UilBell className="size-5 fill-base" />
              </MenuButton>

              <NotificationsList
                notifications={notifications}
                isLoading={isLoading}
              />
            </Menu>
          )}

          <div>{<ProfileDropdown />}</div>
        </div>
      </nav>
    </header>
  );
}

function NotificationsList({ notifications, isLoading }) {
  return (
    <MenuItems
      transition
      anchor="bottom end"
      className="flex flex-col w-96 max-w-full origin-top-right rounded-xl border border-base-400 text-base-content transition duration-100 ease-out [--anchor-gap:var(--spacing-1)] focus:outline-none data-[closed]:scale-95 data-[closed]:opacity-0 z-50 bg-base"
    >
      <div className="flex flex-col p-4">
        <div className="font-bold">Notifications</div>
        {notifications && (
          <div className="text-xs">
            {`You have ${
              notifications?.filter((n) => !n.notification_read)?.length
            } new notifications.`}
          </div>
        )}
      </div>
      {isLoading && (
        <div className="w-full flex justify-center items-center py-6">
          <Loading />
        </div>
      )}
      <div className="flex flex-col divide-y divide-base-400 max-h-[400px] overflow-y-auto">
        {notifications
          ?.sort((a, b) => new Date(b.created_time) - new Date(a.created_time))
          ?.map((notification, index) => (
            <MenuItem
              key={index}
              className={twMerge(
                "py-3 px-4 cursor-pointer",
                notification?.notification_read ? "bg-base" : "bg-primary-lite"
              )}
            >
              {_getNotificationText(notification)}
            </MenuItem>
          ))}
      </div>
    </MenuItems>
  );
}

function _getNotificationText(notification) {
  const titleClass = twMerge(
    "text-sm font-bold",
    notification.notification_read
      ? "text-base-content"
      : "text-primary-lite-content"
  );
  const bodyClass = "text-sm";
  const timeComp = (
    <div className="text-xs text-base-content-low mt-2">
      {new Date(notification.created_time).toLocaleString()}
    </div>
  );
  if (notification.notification_type === "CHANGE_REQUESTED") {
    return (
      <div className="flex gap-4">
        <div className="grow-0 shrink-0">
          <div className="flex justify-center items-center p-2 border border-solid border-warning rounded-full bg-warning-lite">
            <UilChange className="size-5 text-warning" />
          </div>
        </div>
        <div>
          <div className={titleClass}>
            {`7labs has requested some changes.`}
          </div>
          <div className={bodyClass}>
            {notification.notification_object.comment.comment}
          </div>
          {timeComp}
        </div>
      </div>
    );
  }
  if (notification.notification_type === "REVIEW_STATUS_CHANGE") {
    if (notification.notification_object.god === true) {
      return (
        <div className="flex gap-4">
          <div className="grow-0 shrink-0">
            <div className="flex justify-center items-center p-2 border border-solid border-info rounded-full bg-info-lite">
              <UilStar className="size-5 text-info" />
            </div>
          </div>
          <div>
            <div className={titleClass}>Review update</div>
            <div className={bodyClass}>
              {`7labs has ${notification.notification_object.status} a review from ${notification.notification_object.reviewer_company_universal_name}.`}
            </div>
            {timeComp}
          </div>
        </div>
      );
    }
    return (
      <div className="flex gap-4">
        <div className="grow-0 shrink-0">
          <div className="flex justify-center items-center p-2 border border-solid border-info rounded-full bg-info-lite">
            <UilStar className="size-5 text-info" />
          </div>
        </div>
        <div>
          <div className={titleClass}>New review</div>
          <div className={bodyClass}>
            {`${notification.notification_object.reviewer_company_universal_name} has reviewed your organization.`}
          </div>
          {timeComp}
        </div>
      </div>
    );
  }
  if (notification.notification_type === "COMPANY_JOIN_REQUEST") {
    return (
      <div className="flex gap-4">
        <div className="grow-0 shrink-0">
          <div className="flex justify-center items-center p-2 border border-solid border-success rounded-full bg-success-lite">
            <UilUserPlus className="size-5 text-success" />
          </div>
        </div>
        <div>
          <div className={titleClass}>Membership request</div>
          <div className={bodyClass}>
            {`${notification.notification_object.first_name} ${notification.notification_object.last_name} has requested to join your organization.`}
          </div>
          {timeComp}
        </div>
      </div>
    );
  }
}
