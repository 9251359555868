import React, { useContext } from "react";
import { makeAutoObservable } from "mobx";
import { useLocalObservable } from "mobx-react";

class CompanyManagerStore {
  constructor() {
    makeAutoObservable(this);
  }
  showSnackbar = {
    type: "",
    message: "",
  };
  onSnackbarUpdate = () => {};
  setSnackbar = (data) => {
    this.showSnackbar = data;
    this.onSnackbarUpdate();
  };
}

const StoreContext = React.createContext(null);

export const CompanyManagerStoreProvider = (props) => {
  const { children } = props;
  const store = useLocalObservable(() => new CompanyManagerStore());
  return (
    <StoreContext.Provider value={store}>
      {store && children}
    </StoreContext.Provider>
  );
};

export const useCompanyManagerStore = () => {
  return useContext(StoreContext);
};
