import React, { Suspense, useEffect, useState } from "react";
import { SignedIn, SignedOut, useUser } from "@clerk/clerk-react";
import {
  Navigate,
  Outlet,
  RouterProvider,
  createBrowserRouter,
  useLocation,
  useNavigate,
} from "react-router-dom";
import { Loading } from "./components/common/Loading";
import { ErrorBoundary, PublicHeader } from "@7labs-io/b2b-packages";
import CompanyManagerHeader from "./components/CompanyManager/CompanyManagerHeader";
import { twMerge } from "tailwind-merge";
import { ToastContainer } from "react-toastify";
import { Footer } from "@7labs-io/7labs-global-components";
import Broken from "./components/common/Broken";

const NotFound = React.lazy(() => import("./views/NotFound"));
const Profile = React.lazy(() => import("./views/Profile"));
const EditProfile = React.lazy(() => import("./views/EditProfile"));
const Dashboard = React.lazy(() => import("./views/Dashboard"));
const LabsAdmin = React.lazy(() => import("./views/LabsAdmin"));
const AllReviews = React.lazy(() => import("./views/Review/AllReviews"));
const Review = React.lazy(() => import("./views/Review/Review"));
const Login = React.lazy(() => import("./views/Login"));

/**
 * Routes component containing routes for the whole application
 * @returns {JSX}
 */
const Layout = ({ redirect, setRedirect }) => {
  const { isSignedIn, user } = useUser();
  const pathname = window.location.pathname;

  const [memberships, setMemberships] = useState([]);
  useEffect(() => {
    (async function run() {
      const m = (await user?.getOrganizationMemberships()) || [];
      setMemberships(m);
    })();
  }, [user?.primaryEmailAddress.emailAddress]);

  const router = [
    {
      path: "/",
      element: <Main redirect={redirect} setRedirect={setRedirect} />,
      children: [
        {
          path: "login",
          element: isSignedIn ? <Navigate to={redirect} /> : <Login />,
          errorElement: <Broken />,
        },
        {
          path: "profile",
          element: (
            <>
              <SignedIn>
                <Suspense fallback={<Loading message="Loading..." />}>
                  <Profile />
                </Suspense>
              </SignedIn>
              <SignedOut>
                <Navigate to="/login?redirect=profile" />
              </SignedOut>
            </>
          ),
          errorElement: <Broken />,
          children: [
            {
              path: "edit-profile/:title",
              element: (
                <>
                  <SignedIn>
                    <Suspense fallback={<Loading message="Loading..." />}>
                      <EditProfile />
                    </Suspense>
                  </SignedIn>
                  <SignedOut>
                    <Navigate to="/login?redirect=edit-profile" />
                  </SignedOut>
                </>
              ),
              errorElement: <Broken />,
            },
          ],
        },
        {
          path: "dashboard",
          element: (
            <>
              <SignedIn>
                <Suspense fallback={<Loading message="Loading..." />}>
                  <Dashboard />
                </Suspense>
              </SignedIn>
              <SignedOut>
                <Navigate to="/login?redirect=dashboard" />
              </SignedOut>
            </>
          ),
          errorElement: <Broken />,
        },
        {
          path: "review",
          children: [
            {
              index: true,
              element: (
                <>
                  <SignedIn>
                    <Suspense fallback={<Loading message="Loading..." />}>
                      <AllReviews />
                    </Suspense>
                  </SignedIn>
                  <SignedOut>
                    <Navigate to="/login?redirect=review" />
                  </SignedOut>
                </>
              ),
              errorElement: <Broken />,
            },
            {
              path: ":universalName",
              element: (
                <>
                  <SignedIn>
                    <Suspense fallback={<Loading message="Loading..." />}>
                      <Review />
                    </Suspense>
                  </SignedIn>
                  <SignedOut>
                    <Navigate to={`/login?redirect=${pathname}`} />
                  </SignedOut>
                </>
              ),
              errorElement: <Broken />,
            },
          ],
        },
        {
          path: "*",
          element: (
            <Suspense fallback={<Loading message="Loading..." />}>
              <NotFound />
            </Suspense>
          ),
          loginRequired: false,
          errorElement: <Broken />,
        },
      ],
      errorElement: <Broken />,
    },
  ];

  if (memberships[0]?.role === "org:god") {
    router[router.findIndex((r) => r.path === "/")].children.push({
      path: "admin-7labs",
      element: (
        <>
          <SignedIn>
            <Suspense fallback={<Loading message="Loading..." />}>
              <LabsAdmin />
            </Suspense>
          </SignedIn>
          <SignedOut>
            <Navigate to="/login?redirect=admin-7labs" />
          </SignedOut>
        </>
      ),
      loginRequired: true,
      god: true,
      errorElement: <Broken />,
    });
  }

  const prepareRouter = createBrowserRouter(router);

  // if (!isLoaded) {
  //   // You can handle the loading or signed state separately
  //   return <Loading message="Logging you in..." />;
  // }

  return <RouterProvider router={prepareRouter} />;
};

export default Layout;

function Main({ redirect, setRedirect }) {
  const { isSignedIn, isLoaded } = useUser();
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    let rid = urlParams.get("redirect");
    if (rid) {
      setRedirect(rid);
    }
    // if root path, redirect to /profile
    if (location.pathname === "/") {
      navigate(redirect || "/profile");
    }
  }, [location]);

  return (
    <ErrorBoundary>
      {isSignedIn ? <CompanyManagerHeader /> : <PublicHeader />}
      <div className={twMerge("flex flex-col items-center w-full grow")}>
        <div className="w-full h-full grid grid-cols-1 grow justify-center">
          <ErrorBoundary>
            {isLoaded ? <Outlet /> : <Loading message="Logging you in..." />}
          </ErrorBoundary>
        </div>
        <div className="w-full">
          <AsyncFooter />
        </div>
        <ToastContainer
          position="bottom-left"
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          theme="light"
        />
      </div>
    </ErrorBoundary>
  );
}

function AsyncFooter() {
  async function get7labsFooter() {
    const res = await fetch("https://cdn.7labs.io/S3/7labs-config.json");
    const data = await res.json();
    setFooter(data.footer);
  }
  const [footer, setFooter] = useState(false);
  useEffect(() => {
    get7labsFooter();
  }, []);
  return <Footer footer={footer} />;
}
